// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compfight-js": () => import("./../../../src/pages/compfight.js" /* webpackChunkName: "component---src-pages-compfight-js" */),
  "component---src-pages-general-electric-js": () => import("./../../../src/pages/general-electric.js" /* webpackChunkName: "component---src-pages-general-electric-js" */),
  "component---src-pages-iconic-js": () => import("./../../../src/pages/iconic.js" /* webpackChunkName: "component---src-pages-iconic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inkling-js": () => import("./../../../src/pages/inkling.js" /* webpackChunkName: "component---src-pages-inkling-js" */),
  "component---src-pages-invision-js": () => import("./../../../src/pages/invision.js" /* webpackChunkName: "component---src-pages-invision-js" */),
  "component---src-pages-opentable-js": () => import("./../../../src/pages/opentable.js" /* webpackChunkName: "component---src-pages-opentable-js" */),
  "component---src-pages-relay-js": () => import("./../../../src/pages/relay.js" /* webpackChunkName: "component---src-pages-relay-js" */),
  "component---src-pages-symantec-js": () => import("./../../../src/pages/symantec.js" /* webpackChunkName: "component---src-pages-symantec-js" */)
}

